
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

const actions = {
    Setcode (context, value) {
        context.commit("saveData", value);
    }
}
const mutations = {
    saveData (state, value) {
        state.code = value;
    }
}
const state = {
    code: "",
}
export default new Vuex.Store({
    actions,
    mutations,
    state
})