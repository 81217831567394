
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../view/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/pseudo',
    name: 'Pseudo',
    component: () => import('../view/Pseudo.vue')
},
{
    path: '/security',
    name: 'Security',
    component: () => import('../view/Security.vue')
},
]

const router = new VueRouter({
    routes
})

export default router