
<template>
    <div class="container">
        <div class="logo">
            <img src="../assets/logo.png" alt="logo">
        </div>
        <div class="operate">
            <div class="item">
                <span @click="query">仿伪查询</span>
            </div>
            <div class="item">
                <span @click="Wx">官方网站</span>
            </div>
            <div class="item">
                <span @click="Wx">官方微信</span>
            </div>
        </div>
        <div class="footer">
            <img src="../assets/footer.png" alt="茅台">
        </div>
    </div>
</template>
<script>
export default {
    name: 'Home',
    data () {
        return {

        }
    },
    mounted () {
        let currentUrl = window.location.href.split('/')
        let lastElement = currentUrl[3]
        this.$store.dispatch("Setcode", lastElement.substring(0, lastElement.length - 1))
        // this.$store.dispatch("Setcode", '303184836608')
    },
    methods: {
        query () {
            this.$router.push('/pseudo')
        },
        Wx () {
            window.location.href = 'http://www.moutaibaijinjiu.com/'
        }
    }
}

</script>

<style  scoped>
.item {
    margin-top: 40px;
    padding-bottom: 20px;
    /* box-shadow: 0px 0px 4px 0px #9f6f00; */
}

.item span {
    background: url('../assets/box.png') no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #ffff;
    padding: 10px 60px;
}
</style>